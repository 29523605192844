import * as React from "react";
import {useEffect, useRef, useState} from "react";
import styles from "./LiveMap.module.scss";
import {ILiveBoat} from "../LiveHome/LiveHome";
import {getContrastColor} from "../../../utils/color";
import {avg} from "../../../utils/arrayUtils";
import {usePrevious} from "../../../hooks/usePrevious";
import GoogleMap from "google-maps-react-markers";

const MapMarker = (props: any) => {
    return <div {...props}/>;
}

let lastCachedSelfLocation: { lat: number, lng: number };

export const LiveMap = ({boats, selectedBoat, lastBoatSelectTime, path}:
                            {
                                boats: ILiveBoat[],
                                selectedBoat: ILiveBoat | undefined,
                                lastBoatSelectTime: number | undefined,
                                path: ({ lat: number, lng: number } | null)[]
                            }) => {
    const mapRef = useRef<any>();
    const mapsRef = useRef<any>();
    const polyline = useRef<any>();
    const [selfLocation, setSelfLocation] = useState<{ lat: number, lng: number }>(lastCachedSelfLocation);
    const boatsWithLocation = boats.filter(x => x.location);
    const boatsCenter = {
        lat: avg(boatsWithLocation.map(x => x.location!.lat)),
        lng: avg(boatsWithLocation.map(x => x.location!.lng))
    };
    const [center, setCenter] = useState<{ lat: number, lng: number } | undefined>(boatsCenter);
    const [centerOverride, setCenterOverride] = useState();
    const isDragging = useRef(false);

    useEffect(() => {
        if (!mapRef.current || isDragging.current) return;
        if (centerOverride)
            mapRef.current.setCenter(centerOverride);
        else {
            mapRef.current.setCenter(center);
        }
    }, [centerOverride, center]);

    useEffect(() => {
        const t = window.setInterval(() => {
            if (navigator.geolocation)
                navigator.geolocation.getCurrentPosition((position) => {
                    const loc = {lat: position.coords.latitude, lng: position.coords.longitude};
                    if (loc.lat !== lastCachedSelfLocation?.lat || loc.lng !== lastCachedSelfLocation?.lng) {
                        lastCachedSelfLocation = loc;
                        setSelfLocation(loc);
                    }
                }, () => {
                });
        }, 1000);
        return () => clearInterval(t);
    }, []);

    const prevLastBoatSelectTime = usePrevious(lastBoatSelectTime);
    const lastBoatSelectTimeChanged = prevLastBoatSelectTime !== lastBoatSelectTime;

    useEffect(() => {
        if (!centerOverride || lastBoatSelectTimeChanged) {
            setCenter((selectedBoat?.location) || undefined);
        }
    }, [selectedBoat, boats, lastBoatSelectTimeChanged, centerOverride]);

    useEffect(() => {
        if (lastBoatSelectTimeChanged) {
            setCenterOverride(undefined);
        }
    }, [lastBoatSelectTimeChanged]);

    const mapsApiLoaded = (map: any, maps: any) => {
        mapRef.current = map;
        mapsRef.current = maps;
        map.addListener("dragstart", () => {
            isDragging.current = true;
        });
        map.addListener("dragend", () => {
            isDragging.current = false;
            setCenterOverride(mapRef.current.getCenter());
        });
    };

    useEffect(() => {
        const map = mapRef.current;
        const maps = mapsRef.current;
        if (map && maps) {
            if (!polyline.current) {
                polyline.current = new maps.Polyline({
                    path: [],
                    geodesic: true,
                    strokeOpacity: 1.0,
                    strokeWeight: 4,
                });
                polyline.current.setMap(map);
            }
            polyline.current.setPath(path);
            if (selectedBoat !== undefined) {
                polyline.current.setOptions({
                    strokeColor: selectedBoat.color,
                });
            }
        }
    }, [boats, path, selectedBoat]);

    return <div className={styles.map}>
        <GoogleMap
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
            defaultCenter={center}
            defaultZoom={16}
            onGoogleApiLoaded={({map, maps}) => mapsApiLoaded(map, maps)}
            options={{
                streetViewControl: false,
                mapTypeControl: false
            }}>
            {selfLocation &&
                <MapMarker className={styles.mapMarker} lat={selfLocation.lat} lng={selfLocation.lng}
                           style={{
                               backgroundColor: "#000000",
                               border: `2px solid #ffffff`
                           }}/>}
            {boatsWithLocation.map(boat => {
                const contrastColor = getContrastColor(boat.color, "#fff", "#000");
                return <MapMarker key={boat.id}
                                  className={styles.mapMarker}
                                  lat={boat.location!.lat}
                                  lng={boat.location!.lng}
                                  style={{
                                      backgroundColor: boat.color,
                                      border: `2px solid ${contrastColor}`
                                  }}/>
            })}
        </GoogleMap>
    </div>
}