import {mdiFullscreen, mdiFullscreenExit} from "@mdi/js";
import Icon from "@mdi/react";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {SizedBox} from "../../components/base/SizedBox/SizedBox";
import {ExportDialog} from "../../components/dialogs/ExportDialog/ExportDialog";
import DashboardLayout from "../../components/layouts/DashboardLayout/DashboardLayout";
import {AnalyticsHeader} from "../../components/paddlemate/AnalyticsHeader/AnalyticsHeader";
import {AnalyticsPanel, ISlice} from "../../components/paddlemate/AnalyticsPanel/AnalyticsPanel";
import DialogContext from "../../contexts/DialogContext";
import ResponsiveContext from "../../contexts/ResponsiveContext";
import {ITrainingCommon} from "../../typings/ITrainingCommon";
import {Api} from "../../utils/api";
import styles from "./AnalyticsPage.module.css";
import {Spinner} from "../../components/base/Spinner/Spinner";
import {useParams} from "react-router-dom";
import {ITrainingCommonTraining} from "../../typings/ITrainingCommonTraining";
import {max, min} from "../../utils/arrayUtils";
import {ReportProblemDialog} from "../../components/dialogs/ReportProblemDialog/ReportProblemDialog";
import {ITrainingCommonFile} from "../../typings/ITrainingCommonFile";
import {ILapData} from "../../components/paddlemate/AnalyticsPanel/Lap/LapHook";

export const AnalyticsPage = () => {
    const {t} = useTranslation();
    const {fullScreen, setFullScreen} = useContext(ResponsiveContext);
    const {openDialog} = useContext(DialogContext);
    const [trainingData, setTrainingData] = useState<ITrainingCommon | null>(null);
    const [trainingFile, setTrainingFile] = useState<ITrainingCommonFile | null>(null);
    const [extraPanels, setExtraPanels] = useState<number[]>([]);
    const [exportSlice, setExportSlice] = useState<ISlice | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [trainingFileLoading, setTrainingFileLoading] = useState(false);
    const {trainingId: trainingIdParam} = useParams();
    const trainingId = parseInt(trainingIdParam!);
    const [selectedTrainings, setSelectedTrainingsChange] =
        useState<ITrainingCommonTraining[]>([]);

    const [nextId, setNextId] = useState(0);
    const reload = useCallback(() => {
        setNextId(x => x + 1);
    }, []);

    useEffect(() => {
        let cancelled = false;
        (async () => {
            setLoading(true);
            const res = await Api.getTrainingCommon(trainingId);
            if (cancelled) return;
            setTrainingData(res);
        })()
            .catch(console.log)
            .finally(() => {
                setLoading(false);
            });
        (async () => {
            setTrainingFileLoading(true);
            const res = await Api.getTrainingCommonFile(trainingId);
            if (cancelled) return;
            setTrainingFile(res);
        })()
            .catch(console.log)
            .finally(() => {
                setTrainingFileLoading(false);
            });

        return () => {
            cancelled = true;
        };
    }, [trainingId, nextId]);

    useEffect(() => {
        return () => setFullScreen(false);
    }, [setFullScreen]);

    const onAdd = () => {
        setExtraPanels(x => [...x, 1]);
    };

    const onRemovePanel = useCallback((i: number) => {
        setExtraPanels((x) => {
            x.splice(i, 1);
            return [...x];
        });
    }, []);

    const handleSelectionChange = useCallback(
        (slice: ISlice | null) => {
            setExportSlice(slice);
        },
        [],
    );

    const heartRateMax = useMemo(() => trainingData && max(trainingData.trainings
        .filter(x => x.heartRateBpmSeries)
        .map(x => x.maxHeartRateBpm!)), [trainingData]);
    const speedMax = useMemo(() => trainingData?.speedData &&
        trainingData.maxSpeedKph, [trainingData]);
    const strokeRateMax = useMemo(() => trainingData?.strokeData &&
        trainingData.maxStrokeRateSpm, [trainingData]);
    const distancePerStrokeMax = useMemo(() => {
        return trainingData?.distancePerStrokeData &&
            max(trainingData.distancePerStrokeData);
    }, [trainingData]);
    const pullingForceMax = useMemo(() => trainingData && max(
        (trainingData.trainings.map(x =>
            [x.leftPaddlingForceNSeries, x.rightPaddlingForceNSeries]).flat()
            .filter(x => x) as unknown as number[]).flat()), [trainingData]);
    const allDetailedForceSeries = useMemo(() => {
            const forces = trainingData?.trainings.map(x =>
                [x.detailedLeftPaddlingForceNSeries, x.detailedRightPaddlingForceNSeries]).flat()
                .filter(x => x != null) as (number[][] | undefined);
            return forces || [];
        },
        [trainingData]);
    const detailedForceMinMax = useMemo(() => {
            let min = Infinity;
            let max = -Infinity;
            for (const series of allDetailedForceSeries) {
                for (const val of series) {
                    min = Math.min(min, val);
                    max = Math.max(max, val);
                }
            }
            return {
                min,
                max,
            };
        }
        , [allDetailedForceSeries]);
    const {
        accelerationMin,
        accelerationMax,
        gyroscopeMin,
        gyroscopeMax
    } = useMemo(() => {
        let accelerationMin = undefined;
        let accelerationMax = undefined;
        let gyroscopeMin = undefined;
        let gyroscopeMax = undefined;
        if (trainingFile && trainingFile.imuSeries)
            for (const imuElement of trainingFile?.imuSeries) {
                for (let i = 0; i < 6; i++) {
                    const val = imuElement[i];
                    if (i < 3) {
                        accelerationMin = Math.min(accelerationMin || Infinity, val);
                        accelerationMax = Math.max(accelerationMax || -Infinity, val);
                    } else {
                        gyroscopeMin = Math.min(gyroscopeMin || Infinity, val);
                        gyroscopeMax = Math.max(gyroscopeMax || -Infinity, val);
                    }
                }
            }
        return {
            accelerationMin,
            accelerationMax,
            gyroscopeMin,
            gyroscopeMax
        }
    }, [trainingFile]);

    const [lap, setLap] = useState<ILapData | null>(null);

    if (loading || trainingFileLoading)
        return <DashboardLayout>
            <SizedBox height={8}/>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16}}>
                <Spinner/>
            </div>
        </DashboardLayout>;

    if (trainingData == null)
        return <DashboardLayout>
            <SizedBox height={8}/>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16}}>
                {t("loading failed")}
            </div>
        </DashboardLayout>;

    const onDownloadClick = () => {
        openDialog(<ExportDialog training={trainingData}
                                 trainingFile={trainingFile}
                                 slice={exportSlice}
                                 selectedTrainings={selectedTrainings.length === trainingData.trainings.length
                                     ? null : selectedTrainings}
                                 lap={lap}/>);
    };

    const onSent = () => {
        reload();
    };

    const onReportProblemClick = () => {
        openDialog(<ReportProblemDialog dialogKey={"report-problem"} trainingId={trainingData.id} onSent={onSent}/>);
    };

    return (
        <DashboardLayout fullScreen={fullScreen}>
            <SizedBox height={8}/>
            <AnalyticsHeader
                onDownloadClick={onDownloadClick}
                onReportProblemClick={onReportProblemClick}
                trainingData={trainingData}
            />
            <AnalyticsPanel
                trainingCommon={trainingData}
                trainingCommonFile={trainingFile}
                onSelectionChange={handleSelectionChange}
                closable={true}
                showUserSeries={true}
                onSelectedTrainingsChanged={setSelectedTrainingsChange}
                heartRateMax={heartRateMax!}
                speedMax={speedMax!}
                strokeRateMax={strokeRateMax!}
                distancePerStrokeMax={distancePerStrokeMax!}
                pullingForceMax={pullingForceMax!}
                detailedPullingForceMin={detailedForceMinMax.min}
                detailedPullingForceMax={detailedForceMinMax.max}
                accelerationMin={accelerationMin!}
                accelerationMax={accelerationMax!}
                gyroscopeMin={gyroscopeMin!}
                gyroscopeMax={gyroscopeMax!}
                onChange={reload}
                onLapChanged={setLap}
            />
            <SizedBox height={20}/>
            {extraPanels.map((_, i) => (
                <AnalyticsPanel
                    key={i}
                    panelNumber={i + 2}
                    trainingCommon={trainingData}
                    trainingCommonFile={trainingFile}
                    closable={false}
                    onClose={() => onRemovePanel(i)}
                    showSettingsFirst={true}
                    heartRateMax={heartRateMax!}
                    speedMax={speedMax!}
                    distancePerStrokeMax={distancePerStrokeMax!}
                    strokeRateMax={strokeRateMax!}
                    pullingForceMax={pullingForceMax!}
                    detailedPullingForceMin={detailedForceMinMax.min}
                    detailedPullingForceMax={detailedForceMinMax.max}
                    accelerationMin={accelerationMin!}
                    accelerationMax={accelerationMax!}
                    gyroscopeMin={gyroscopeMin!}
                    gyroscopeMax={gyroscopeMax!}
                />
            ))}

            {extraPanels.length < 3 &&
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div onClick={onAdd} style={{
                        cursor: "pointer",
                        margin: 20,
                        backgroundColor: "white",
                        borderRadius: 4,
                        padding: "4px 12px"
                    }}>
                        + {t("add new panel")}
                    </div>
                </div>
            }

            <SizedBox height={50}/>
            <div className={styles.fullScreenButton}
                 onClick={() => setFullScreen(!fullScreen)}>
                <Icon
                    size={1.5}
                    path={!fullScreen ? mdiFullscreen : mdiFullscreenExit}
                />
            </div>
        </DashboardLayout>
    );
};
